import React, {useContext, useEffect} from "react"
import { Col, Row } from "react-bootstrap"
import Plan from '../Plan'
import { updateSupportDisplay } from "../../contexts/actions"
import { AppContext } from "../../contexts/global.context"

function PlanCategory({plans}) {
  const {dispatch } = useContext(AppContext);
  
  useEffect(()=> {
    dispatch(updateSupportDisplay(true))
  }, [])

    const packages = (plans).map((plan)=> (
      <Col key={plan.id} md={3} >
          <Plan plan={{
            ...plan, 
            uploadSpeed: plan.upload_speed, 
            donwloadSpeed: plan.download_speed,
            speedunit: plan.speed_unit}}/>
      </Col>))

  return (<>
  <Row className="">
    <Col>
      <h1 className="text-center page-header">Unlimited Internet</h1>
      <h5 className="text-center page-subheader">Select your plan and we will get you step up in minutes. No hidden charges, choose your plan.</h5>
    </Col>
  </Row>
  <Row className="mt-3">
    {plans?.length> 0 ? packages: <Col><p>no package</p></Col>  }
  </Row>


  </>)
}

export default PlanCategory;
