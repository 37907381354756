import React from 'react';
import { ErrorPage } from '../../components/Error';

const DefaultError = () => {
    const message = 'Well, something went wrong.'
    const code = 500
    return <ErrorPage message={message} errorCode={code} />
}

export default DefaultError;
