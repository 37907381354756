import * as Headless from "@headlessui/react"
import clsx from "clsx"
import React, { forwardRef } from "react"
import { Link } from "./link"

const styles = {
  base: [
    // Base
    "tw-relative tw-isolate tw-inline-flex tw-items-center tw-justify-center tw-gap-x-2 tw-rounded-lg tw-border tw-text-base/6 tw-font-semibold",
    // Sizing
    "tw-px-[calc(theme(spacing[3.5])-1px)] tw-py-[calc(theme(spacing[2.5])-1px)] tw-sm:px-[calc(theme(spacing.3)-1px)] tw-sm:py-[calc(theme(spacing[1.5])-1px)] tw-sm:text-sm/6",
    // Focus
    "tw-focus:outline-none tw-data-[focus]:outline tw-data-[focus]:outline-2 tw-data-[focus]:outline-offset-2 tw-data-[focus]:outline-blue-500",
    // Disabled
    "tw-data-[disabled]:opacity-50",
    // Icon
    "tw-[&>[data-slot=icon]]:-mx-0.5 tw-[&>[data-slot=icon]]:my-0.5 tw-[&>[data-slot=icon]]:size-5 tw-[&>[data-slot=icon]]:shrink-0 tw-[&>[data-slot=icon]]:text-[--btn-icon] tw-[&>[data-slot=icon]]:sm:my-1 tw-[&>[data-slot=icon]]:sm:size-4 tw-forced-colors:[--btn-icon:ButtonText] tw-forced-colors:data-[hover]:[--btn-icon:ButtonText]",
  ],
  solid: [
    // Optical border, implemented as the button background to avoid corner artifacts
    "tw-border-transparent tw-bg-[--btn-border]",
    // Dark mode: border is rendered on `after` so background is set to button background
    "tw-dark:bg-[--btn-bg]",
    // Button background, implemented as foreground layer to stack on top of pseudo-border layer
    "tw-before:absolute tw-before:inset-0 tw-before:-z-10 tw-before:rounded-[calc(theme(borderRadius.lg)-1px)] tw-before:bg-[--btn-bg]",
    // Drop shadow, applied to the inset `before` layer so it blends with the border
    "tw-before:shadow",
    // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
    "tw-dark:before:hidden",
    // Dark mode: Subtle white outline is applied using a border
    "tw-dark:border-white/5",
    // Shim/overlay, inset to match button foreground and used for hover state + highlight shadow
    "tw-after:absolute tw-after:inset-0 tw-after:-z-10 tw-after:rounded-[calc(theme(borderRadius.lg)-1px)]",
    // Inner highlight shadow
    "tw-after:shadow-[shadow:inset_0_1px_theme(colors.white/15%)]",
    // White overlay on hover
    "tw-after:data-[active]:bg-[--btn-hover-overlay] tw-after:data-[hover]:bg-[--btn-hover-overlay]",
    // Dark mode: `after` layer expands to cover entire button
    "tw-dark:after:-inset-px tw-dark:after:rounded-lg",
    // Disabled
    "tw-before:data-[disabled]:shadow-none tw-after:data-[disabled]:shadow-none",
  ],
  outline: [
    // Base
    "tw-border-zinc-950/10 tw-text-zinc-950 tw-data-[active]:bg-zinc-950/[2.5%] tw-data-[hover]:bg-zinc-950/[2.5%]",
    // Dark mode
    "tw-dark:border-white/15 tw-dark:text-white tw-dark:[--btn-bg:transparent] tw-dark:data-[active]:bg-white/5 tw-dark:data-[hover]:bg-white/5",
    // Icon
    "tw-[--btn-icon:theme(colors.zinc.500)] tw-data-[active]:[--btn-icon:theme(colors.zinc.700)] tw-data-[hover]:[--btn-icon:theme(colors.zinc.700)] tw-dark:data-[active]:[--btn-icon:theme(colors.zinc.400)] tw-dark:data-[hover]:[--btn-icon:theme(colors.zinc.400)]",
  ],
  plain: [
    // Base
    "tw-border-transparent tw-text-zinc-950 tw-data-[active]:bg-zinc-950/5 tw-data-[hover]:bg-zinc-950/5",
    // Dark mode
    "tw-dark:text-white tw-dark:data-[active]:bg-white/10 tw-dark:data-[hover]:bg-white/10",
    // Icon
    "tw-[--btn-icon:theme(colors.zinc.500)] tw-data-[active]:[--btn-icon:theme(colors.zinc.700)] tw-data-[hover]:[--btn-icon:theme(colors.zinc.700)] tw-dark:[--btn-icon:theme(colors.zinc.500)] tw-dark:data-[active]:[--btn-icon:theme(colors.zinc.400)] tw-dark:data-[hover]:[--btn-icon:theme(colors.zinc.400)]",
  ],
  colors: {
    "dark/zinc": [
      "tw-text-white tw-[--btn-bg:theme(colors.zinc.900)] tw-[--btn-border:theme(colors.zinc.950/90%)] tw-[--btn-hover-overlay:theme(colors.white/10%)]",
      "tw-dark:text-white tw-dark:[--btn-bg:theme(colors.zinc.600)] tw-dark:[--btn-hover-overlay:theme(colors.white/5%)]",
      "tw-[--btn-icon:theme(colors.zinc.400)] tw-data-[active]:[--btn-icon:theme(colors.zinc.300)] tw-data-[hover]:[--btn-icon:theme(colors.zinc.300)]",
    ],
    light: [
      "tw-text-zinc-950 tw-[--btn-bg:white] tw-[--btn-border:theme(colors.zinc.950/10%)] tw-[--btn-hover-overlay:theme(colors.zinc.950/2.5%)] tw-data-[active]:[--btn-border:theme(colors.zinc.950/15%)] tw-data-[hover]:[--btn-border:theme(colors.zinc.950/15%)]",
      "tw-dark:text-white tw-dark:[--btn-hover-overlay:theme(colors.white/5%)] tw-dark:[--btn-bg:theme(colors.zinc.800)]",
      "tw-[--btn-icon:theme(colors.zinc.500)] tw-data-[active]:[--btn-icon:theme(colors.zinc.700)] tw-data-[hover]:[--btn-icon:theme(colors.zinc.700)] tw-dark:[--btn-icon:theme(colors.zinc.500)] tw-dark:data-[active]:[--btn-icon:theme(colors.zinc.400)] tw-dark:data-[hover]:[--btn-icon:theme(colors.zinc.400)]",
    ],
    "dark/white": [
      "tw-text-white tw-[--btn-bg:theme(colors.zinc.900)] tw-[--btn-border:theme(colors.zinc.950/90%)] tw-[--btn-hover-overlay:theme(colors.white/10%)]",
      "tw-dark:text-zinc-950 tw-dark:[--btn-bg:white] tw-dark:[--btn-hover-overlay:theme(colors.zinc.950/5%)]",
      "tw-[--btn-icon:theme(colors.zinc.400)] tw-data-[active]:[--btn-icon:theme(colors.zinc.300)] tw-data-[hover]:[--btn-icon:theme(colors.zinc.300)] tw-dark:[--btn-icon:theme(colors.zinc.500)] tw-dark:data-[active]:[--btn-icon:theme(colors.zinc.400)] tw-dark:data-[hover]:[--btn-icon:theme(colors.zinc.400)]",
    ],
    dark: [
      "tw-text-white tw-[--btn-bg:theme(colors.zinc.900)] tw-[--btn-border:theme(colors.zinc.950/90%)] tw-[--btn-hover-overlay:theme(colors.white/10%)]",
      "tw-dark:[--btn-hover-overlay:theme(colors.white/5%)] tw-dark:[--btn-bg:theme(colors.zinc.800)]",
      "tw-[--btn-icon:theme(colors.zinc.400)] tw-data-[active]:[--btn-icon:theme(colors.zinc.300)] tw-data-[hover]:[--btn-icon:theme(colors.zinc.300)]",
    ],
    white: [
      "tw-text-zinc-950 tw-[--btn-bg:white] tw-[--btn-border:theme(colors.zinc.950/10%)] tw-[--btn-hover-overlay:theme(colors.zinc.950/2.5%)] tw-data-[active]:[--btn-border:theme(colors.zinc.950/15%)] tw-data-[hover]:[--btn-border:theme(colors.zinc.950/15%)]",
      "tw-dark:[--btn-hover-overlay:theme(colors.zinc.950/5%)]",
      "tw-[--btn-icon:theme(colors.zinc.400)] tw-data-[active]:[--btn-icon:theme(colors.zinc.500)] tw-data-[hover]:[--btn-icon:theme(colors.zinc.500)]",
    ],
    zinc: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.zinc.600)] tw-[--btn-border:theme(colors.zinc.700/90%)]",
      "tw-dark:[--btn-hover-overlay:theme(colors.white/5%)]",
      "tw-[--btn-icon:theme(colors.zinc.400)] tw-data-[active]:[--btn-icon:theme(colors.zinc.300)] tw-data-[hover]:[--btn-icon:theme(colors.zinc.300)]",
    ],
    indigo: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.indigo.500)] tw-[--btn-border:theme(colors.indigo.600/90%)]",
      "tw-[--btn-icon:theme(colors.indigo.300)] tw-data-[active]:[--btn-icon:theme(colors.indigo.200)] tw-data-[hover]:[--btn-icon:theme(colors.indigo.200)]",
    ],
    cyan: [
      "tw-text-cyan-950 tw-[--btn-bg:theme(colors.cyan.300)] tw-[--btn-border:theme(colors.cyan.400/80%)] tw-[--btn-hover-overlay:theme(colors.white/25%)]",
      "tw-[--btn-icon:theme(colors.cyan.500)]",
    ],
    red: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.red.600)] tw-[--btn-border:theme(colors.red.700/90%)]",
      "tw-[--btn-icon:theme(colors.red.300)] tw-data-[active]:[--btn-icon:theme(colors.red.200)] tw-data-[hover]:[--btn-icon:theme(colors.red.200)]",
    ],
    orange: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.orange.500)] tw-[--btn-border:theme(colors.orange.600/90%)]",
      "tw-[--btn-icon:theme(colors.orange.300)] tw-data-[active]:[--btn-icon:theme(colors.orange.200)] tw-data-[hover]:[--btn-icon:theme(colors.orange.200)]",
    ],
    amber: [
      "tw-text-amber-950 tw-[--btn-hover-overlay:theme(colors.white/25%)] tw-[--btn-bg:theme(colors.amber.400)] tw-[--btn-border:theme(colors.amber.500/80%)]",
      "tw-[--btn-icon:theme(colors.amber.600)]",
    ],
    yellow: [
      "tw-text-yellow-950 tw-[--btn-hover-overlay:theme(colors.white/25%)] tw-[--btn-bg:theme(colors.yellow.300)] tw-[--btn-border:theme(colors.yellow.400/80%)]",
      "tw-[--btn-icon:theme(colors.yellow.600)] tw-data-[active]:[--btn-icon:theme(colors.yellow.700)] tw-data-[hover]:[--btn-icon:theme(colors.yellow.700)]",
    ],
    lime: [
      "tw-text-lime-950 tw-[--btn-hover-overlay:theme(colors.white/25%)] tw-[--btn-bg:theme(colors.lime.300)] tw-[--btn-border:theme(colors.lime.400/80%)]",
      "tw-[--btn-icon:theme(colors.lime.600)] tw-data-[active]:[--btn-icon:theme(colors.lime.700)] tw-data-[hover]:[--btn-icon:theme(colors.lime.700)]",
    ],
    green: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.green.600)] tw-[--btn-border:theme(colors.green.700/90%)]",
      "tw-[--btn-icon:theme(colors.white/60%)] tw-data-[active]:[--btn-icon:theme(colors.white/80%)] tw-data-[hover]:[--btn-icon:theme(colors.white/80%)]",
    ],
    emerald: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.emerald.600)] tw-[--btn-border:theme(colors.emerald.700/90%)]",
      "tw-[--btn-icon:theme(colors.white/60%)] tw-data-[active]:[--btn-icon:theme(colors.white/80%)] tw-data-[hover]:[--btn-icon:theme(colors.white/80%)]",
    ],
    teal: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.teal.600)] tw-[--btn-border:theme(colors.teal.700/90%)]",
      "tw-[--btn-icon:theme(colors.white/60%)] tw-data-[active]:[--btn-icon:theme(colors.white/80%)] tw-data-[hover]:[--btn-icon:theme(colors.white/80%)]",
    ],
    sky: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.sky.500)] tw-[--btn-border:theme(colors.sky.600/80%)]",
      "tw-[--btn-icon:theme(colors.white/60%)] tw-data-[active]:[--btn-icon:theme(colors.white/80%)] tw-data-[hover]:[--btn-icon:theme(colors.white/80%)]",
    ],
    blue: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.blue.600)] tw-[--btn-border:theme(colors.blue.700/90%)]",
      "tw-[--btn-icon:theme(colors.blue.400)] tw-data-[active]:[--btn-icon:theme(colors.blue.300)] tw-data-[hover]:[--btn-icon:theme(colors.blue.300)]",
    ],
    violet: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.violet.500)] tw-[--btn-border:theme(colors.violet.600/90%)]",
      "tw-[--btn-icon:theme(colors.violet.300)] tw-data-[active]:[--btn-icon:theme(colors.violet.200)] tw-data-[hover]:[--btn-icon:theme(colors.violet.200)]",
    ],
    purple: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.purple.500)] tw-[--btn-border:theme(colors.purple.600/90%)]",
      "tw-[--btn-icon:theme(colors.purple.300)] tw-data-[active]:[--btn-icon:theme(colors.purple.200)] tw-data-[hover]:[--btn-icon:theme(colors.purple.200)]",
    ],
    fuchsia: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.fuchsia.500)] tw-[--btn-border:theme(colors.fuchsia.600/90%)]",
      "tw-[--btn-icon:theme(colors.fuchsia.300)] tw-data-[active]:[--btn-icon:theme(colors.fuchsia.200)] tw-data-[hover]:[--btn-icon:theme(colors.fuchsia.200)]",
    ],
    pink: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.pink.500)] tw-[--btn-border:theme(colors.pink.600/90%)]",
      "tw-[--btn-icon:theme(colors.pink.300)] tw-data-[active]:[--btn-icon:theme(colors.pink.200)] tw-data-[hover]:[--btn-icon:theme(colors.pink.200)]",
    ],
    rose: [
      "tw-text-white tw-[--btn-hover-overlay:theme(colors.white/10%)] tw-[--btn-bg:theme(colors.rose.500)] tw-[--btn-border:theme(colors.rose.600/90%)]",
      "tw-[--btn-icon:theme(colors.rose.300)] tw-data-[active]:[--btn-icon:theme(colors.rose.200)] tw-data-[hover]:[--btn-icon:theme(colors.rose.200)]",
    ],
  },
}

export const Button = forwardRef(function Button(
  { color, outline, plain, className, children, ...props },
  ref
) {
  let classes = clsx(
    className,
    styles.base,
    outline
      ? styles.outline
      : plain
      ? styles.plain
      : clsx(styles.solid, styles.colors[color ?? "dark/zinc"])
  )

  return "href" in props ? (
    <Link {...props} className={classes} ref={ref}>
      <TouchTarget>{children}</TouchTarget>
    </Link>
  ) : (
    <Headless.Button
      {...props}
      className={clsx(classes, "cursor-default")}
      ref={ref}
    >
      <TouchTarget>{children}</TouchTarget>
    </Headless.Button>
  )
})

/**
 * Expand the hit area to at least 44×44px on touch devices
 */
export function TouchTarget({ children }) {
  return (
    <>
      <span
        className="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden"
        aria-hidden="true"
      />
      {children}
    </>
  )
}
