import React, { useState, useRef, useEffect, useContext } from "react"
import { Button, Card, Overlay, Tooltip } from "react-bootstrap"
import { AppContext } from "../../contexts/global.context"

const Support = ({company}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const {showSupportButton } = useContext(AppContext);
  const target = useRef(null)
  const {email, phone} = company;

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const onclick = () => {
    setShowTooltip(false)
  }

  const toogleBtn = showSupportButton ? 'tw-block':  'tw-hidden'
  const css = `floating-button-container ${toogleBtn}`



  return (
    <div className={css}>
      <Button ref={target} onClick={toggleTooltip} className="floating-button">
        Support
      </Button>
      <Overlay
        target={target.current}
        show={showTooltip}
        placement="top"
       
      >
        {(props) => (
          <Tooltip    id="tooltip-contained" {...props}  onClick={onclick}>
            <Card  >
              <Card.Header className="text-black">Support Contact</Card.Header>
              <Card.Body>
                <h5 className="text-black">Phone Number</h5>
                <p className="text-black s-content">{phone || '0705814794'}</p>
                <h5 className="text-black">Email</h5>
                <p className="text-black s-content">{email || '0705814794'}</p>
              </Card.Body>
            </Card>
          </Tooltip>
        )}
      </Overlay>
    </div>
  )
}

export default Support
