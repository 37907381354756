import * as Headless from "@headlessui/react"
import clsx from "clsx"

import { Text } from "./text"

// ... existing code ...

const sizes = {
  xs: "tw-sm:max-w-xs",
  sm: "tw-sm:max-w-sm",
  md: "tw-sm:max-w-md",
  lg: "tw-sm:max-w-lg",
  xl: "tw-sm:max-w-xl",
  "2xl": "tw-sm:max-w-2xl",
  "3xl": "tw-sm:max-w-3xl",
  "4xl": "tw-sm:max-w-4xl",
  "5xl": "tw-sm:max-w-5xl",
}

export function Alert({ size = "md", className, children, ...props }) {
  return (
    <Headless.Dialog {...props}>
      <Headless.DialogBackdrop
        transition
        className="tw-fixed tw-inset-0 tw-flex tw-w-screen tw-justify-center tw-overflow-y-auto tw-bg-zinc-950/15 tw-px-2 tw-py-2 tw-transition tw-duration-100 tw-focus:outline-0 tw-data-[closed]:opacity-0 tw-data-[enter]:ease-out tw-data-[leave]:ease-in tw-sm:px-6 tw-sm:py-8 tw-lg:px-8 tw-lg:py-16 tw-dark:bg-zinc-950/50"
      />

      <div className="tw-fixed tw-inset-0 tw-w-screen tw-overflow-y-auto tw-pt-6 tw-sm:pt-0">
        <div className="tw-grid tw-min-h-full tw-grid-rows-[1fr_auto_1fr] tw-justify-items-center tw-p-8 tw-sm:grid-rows-[1fr_auto_3fr] tw-sm:p-4">
          <Headless.DialogPanel
            transition
            className={clsx(
              className,
              sizes[size],
              "tw-row-start-2 tw-w-full tw-rounded-2xl tw-bg-white tw-p-8 tw-shadow-lg tw-ring-1 tw-ring-zinc-950/10 tw-sm:rounded-2xl tw-sm:p-6 tw-dark:bg-zinc-900 tw-dark:ring-white/10 tw-forced-colors:outline",
              "tw-transition tw-duration-100 tw-will-change-transform tw-data-[closed]:data-[enter]:scale-95 tw-data-[closed]:opacity-0 tw-data-[enter]:ease-out tw-data-[leave]:ease-in"
            )}
          >
            {children}
          </Headless.DialogPanel>
        </div>
      </div>
    </Headless.Dialog>
  )
}

export function AlertTitle({ className, ...props }) {
  return (
    <Headless.DialogTitle
      {...props}
      className={clsx(
        className,
        "tw-text-balance tw-text-center tw-text-base/6 tw-font-semibold tw-text-zinc-950 tw-sm:text-wrap tw-sm:text-left tw-sm:text-sm/6 tw-dark:text-white"
      )}
    />
  )
}

export function AlertDescription({ className, ...props }) {
  return (
    <Headless.Description
      as={Text}
      {...props}
      className={clsx(
        className,
        "tw-mt-2 tw-text-pretty tw-text-center tw-sm:text-left"
      )}
    />
  )
}

export function AlertBody({ className, ...props }) {
  return <div {...props} className={clsx(className, "tw-mt-4")} />
}

export function AlertActions({ className, ...props }) {
  return (
    <div
      {...props}
      className={clsx(
        className,
        "tw-mt-6 tw-flex tw-flex-col-reverse tw-items-center tw-justify-end tw-gap-3 tw-*:w-full tw-sm:mt-4 tw-sm:flex-row tw-sm:*:w-auto"
      )}
    />
  )
}
