import React from 'react';
import { ErrorPage } from '../../components/Error';

const UnauthorizedError = () => {
    const message = 'Unauthorized Access'
    const description = "Please disconnect your WIFI connection and try to reconnect. If this persist, contact support"
    const code = 401
    return <ErrorPage message={message} errorCode={code} description={description} />
}

export default UnauthorizedError;
