import React, { useEffect, useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import { Modal, ToastContainer, Spinner, Toast } from "react-bootstrap";
import { getCurrentUser } from "../../apis";


function Login() {
  const url_to_submit = `${process.env.REACT_APP_API_URL}/captive/portal/test`
    const [show, setShow] = useState(false)
    const [showToast, setToast] = useState(false);
    const [customer, setCustomer] = useState(null)
    const [captive, setCaptive] = useState(null)

    useEffect(() => {
      setToast(true)
        const fetchData = async () => {
          const {customer, captive} = await getCurrentUser()
          setCustomer(customer)
          setCaptive(captive)

          // automatic redirection
          document.getElementById('formsubmit').setAttribute('action', captive.link_login_only || url_to_submit)
          document.getElementById('formsubmit').setAttribute('name', 'sendin')
          document.sendin.username.value = customer.username.toUpperCase()
          document.sendin.password.value = customer.hash
          document.sendin.dst.value = 'https://www.google.com/'
          document.getElementById('formsubmit').submit()
        }
        fetchData()
      }, []);
   

  return (
    <Container>
      <Row>
        <Col>
          <Form name="sendin" action={url_to_submit} id="formsubmit" method="post">
            <Form.Group className="mb-3" controlId="formBasicEmail" >
              <Form.Control hidden={true} type="text" placeholder="username"  name="username"/>            
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control hidden={true} type="text" placeholder="Password" name="password" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="dst">
              <Form.Control hidden={true} type="text" placeholder="Password" name="dst" />
            </Form.Group>
          </Form>
          
        </Col>
      </Row>
      {/* modal */}
      <Modal show={show}  centered backdrop="static">
          <Modal.Body className="align-col-center">
            <div className="d-flex space-between-elements">
              <Spinner
                animation="border"
                variant="primary"
                className="pr-2"
              ></Spinner>
              <h6 className="ml-3">Please wait....</h6>
            </div>
          </Modal.Body>
        </Modal>

        {/* toast */}
        <ToastContainer position="top-end" className="p-3">
        <Toast onClose={() => setToast(false)} show={showToast} delay={3000} autohide >
          <Toast.Header onClick={()=> () => setToast(false)} closeButton= {false}>
            <strong  className="me-auto text-primary">Success</strong>
          </Toast.Header >
          <Toast.Body className="text-success">Payment Successful</Toast.Body>
        </Toast>
        </ToastContainer>
     
    </Container>
  )
}

export default Login
