import React from 'react';
import { ErrorPage } from '../../components/Error';

const IspSuspendedError = () => {
    const message = 'ISP is suspended!'
    const code = 403
    return <ErrorPage message={message} errorCode={code} />
}

export default IspSuspendedError;
