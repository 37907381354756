import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../components/CatalystUI/alert'
import { Button } from '../components/CatalystUI/button'

export default function IndexPage() {
    // useEffect(()=> {
    //     window.location.href = 'https://diralink.com';
        
    // }, [])
    let [isOpen, setIsOpen] = useState(false)
    return (
      <>
        <Button type="button" className="tw-bg-amber-950 tw-rounded-md tw-ml-2" onClick={() => setIsOpen(true)}>
          Refund payment
        </Button>
        <Alert open={isOpen} onClose={setIsOpen}>
          <AlertTitle className="tw-font-mono">Are you sure you want to refund this payment?</AlertTitle>
          <AlertDescription>
            The refund will be reflected in the customer’s bank account 2 to 3 business days after processing.
          </AlertDescription>
          <AlertActions>
            <Button plain onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button onClick={() => setIsOpen(false)}>Refund</Button>
          </AlertActions>
        </Alert>
      </>
    )
}