import React from 'react';
import { ErrorPage } from '../../components/Error';

const ValidationError = () => {
    const message = 'Router is not configured correctly.'
    const code = 400
    return <ErrorPage message={message} errorCode={code} />
}

export default ValidationError;
