import React, { useContext } from "react"
import { AppContext } from "../../contexts/global.context"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Card, Button, Row, Container, Col } from "react-bootstrap"
import { Check2Circle } from "react-bootstrap-icons"
import { timeUnitParser } from "../../helpers"
import { selectPackage } from "../../contexts/actions"
import PlanAttribute from "../PlanAttribute"

function Plan({ plan }) {
  let navigate = useNavigate()
  const { dispatch } = useContext(AppContext)

  const selectAndUpdate = () => {
    dispatch(selectPackage(plan))
      navigate(`/renew/${plan.uuid}`, { replace: true })
  }
  return (
    <Container>
      <Row>
        <Col>
          <Card className="mb-3">
            <Card.Header className="tw-bg-sky-600">
              <span className="tw-text-white tw-text-lg">{plan?.name}</span>
            </Card.Header>
            <Card.Body>
              <div className="tw-mb-3 tw-divide-y tw-divide-dashed">
              <PlanAttribute name={'Duration'} value={`${plan?.duration} ${plan?.time_unit}`} />
              <PlanAttribute name={'Upload Speed'} value={`${plan?.upload_speed} ${plan?.speed_unit}`} />
              <PlanAttribute name={'Download Speed'} value={`${plan?.download_speed} ${plan?.speed_unit}`} />
              <PlanAttribute name={'Bundle Size'} value={`${plan?.bundle_size} ${plan?.bundle_unit}`} />
              <PlanAttribute name={'Price'} value={`${plan?.currency} ${plan?.price}`} />
              </div>
              <Button onClick={selectAndUpdate} className="tw-bg-sky-600">
                Subscribe
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Plan
