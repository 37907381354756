import React from "react"

const PlanAttribute = ({ name, value }) => {
  return (
    <div className="tw-flex tw-justify-between tw-my-1 tw-py-2">
      <span className="tw-text-sm md:tw-text-base">{name}</span>
      <span className="tw-text-sm md:tw-text-base tw-font-mono">{value}</span>
    </div>
  )
}

export default PlanAttribute
