import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.withCredentials = true;
axios.defaults.timeout = 5000 // 5 seconds


export const getPackages = async (companyid) => {
  try {
    const {data} = await axios.get(`/v1/api/captive/plans/list/${companyid}`)
    return data.data
  } catch (error) {
    throw error
  }
}

export const getPlan = async (packageid) => {
  try {
    const {data} = await axios.get(`/v1/api/captive/plans/${packageid}`)
    return data.data
  } catch (error) {
    throw error
  }
}

export const initiateMpesaStkPayment = async (paymentData) => {
  try {
    const {data} = await axios.post("/v1/api/captive/payments", paymentData, {
      timeout: 10000,
    })
    return data.data
  } catch (error) {
    throw error
  }
}


export const checkPayment = async (ref_id) => {
  try {
    const {data} = await axios.get(`/v1/api/captive/payments/${ref_id}`)
    return data.data
  } catch (error) {
    throw error
  }
}





export const getCurrentUser = async () => {
  try {
    const {data} = await axios.get(`/v1/api/captive/customer`)
    return data.data
  } catch (error) {
    throw error
  }
}

export const getIspInfo = async () => {
  try {
    const {data} = await axios.get(`/v1/api/captive/company`)
    return data.data
  } catch (error) {
    throw error
  }
}
