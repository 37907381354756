import React from 'react';
import { ErrorPage } from '../../components/Error';

const IspMissingError = () => {
    const message = 'ISP has not been configured!'
    const code = 500
    return <ErrorPage message={message} errorCode={code} />
}

export default IspMissingError;
