import React, { useState, useEffect, useContext } from "react"
import { BoxLoading } from "react-loadingg"
import { useNavigate ,useSearchParams, useParams } from "react-router-dom"

import {
  Card,
  Col,
  Container,
  Row,
  Modal,
  Spinner,
  Button,
  Form,
  Toast,
  ToastContainer,
} from "react-bootstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import PaymentInfo from "../PaymentInfo"
import {
  initiateMpesaStkPayment, getPlan} from "../../apis"
import { AppContext } from "../../contexts/global.context";
import { setCurrentUrl } from "../../contexts/actions"
const countryCode = {
  "KES": "254"
}
function Renew() {
  const {plan_id} = useParams();
  const [show, setShow] = useState(false)
  const [showToast, setToast] = useState(false)
  const [serverError, setServerError] = useState(null)
  
  const [buttonMessage, setButtonMessage] = useState("Pay Now")
  const [disableButton, setDisableButton] = useState(false)
  
  const [isLoading, setIsLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const [plan, setPlan ] = useState(null)
  const [triedBefore, setTriedBefore] = useState(Number(searchParams.get('retry')) || 2)

  const navigate = useNavigate()
  const {dispatch} = useContext(AppContext);

  useEffect(()=> {
    if(triedBefore ===1) {
      setButtonMessage('Retry Again')
    }
  }, [triedBefore])



  useEffect(()=> {
    const fetchData = async () => {
      try {
        const plan = await getPlan(plan_id)
        setPlan(plan)
      } catch (error) {
        throw error
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [plan_id])

  useEffect(()=> {
    const handleBeforeUnload = (ev) =>  {
      ev.preventDefault()
      ev.returnValue = 'Are you sure you want to exit this Page'
    }
    window.addEventListener('beforeunload',handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }

  }, [])

  

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = async (values) => {
    // prepare data to send to backend
    const { payment } = values;
    
    try {
      setDisableButton(true)
      setButtonMessage("Processing Payment")
      let phone_number = payment.mpesaPhoneNumber.slice(1)

      const paymentPayload = {
        params: {
          phone_number: `${countryCode[plan.currency]}${phone_number}`,
          plan_id: plan.uuid
        }
      }
      // initiate mpesa payment
      const {ref_id, status} = await initiateMpesaStkPayment(paymentPayload)
      if (status === 'pending') {
        // redirect to payment, user has received stk push
        dispatch(setCurrentUrl(window.location.href))
        navigate(`/check/payment/${ref_id}`, { replace: true })
      }
      
    } catch (error) {
      if (error.response) {
        setButtonMessage("Retry")
        setDisableButton(false)
        setServerError(error.response.data)
        setToast(true)
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <Container><BoxLoading color="#0d6efd" /></Container>
      ) : (
        <Container>
          <Row className="align-col-center">
            <Col md={8}>
              <Card className="py-3">
                <Card.Body>
                  <Form onSubmit={formik.handleSubmit}>
                    <PaymentInfo formik={formik} selectedPlan={plan} />
                    <div className="space-between-elements">
                      <Button
                        disabled={disableButton}
                        variant="primary"
                        type="submit"
                      >
                        {buttonMessage}
                      </Button>
                      <Button
                        disabled={disableButton}
                        onClick={() =>
                          navigate(`/plans/${plan?.company_id}`, {
                            replace: true,
                          })
                        }
                        className="ml-3"
                        variant="danger"
                      >
                        Back
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* modal */}
          <Modal show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Body className="align-col-center">
              <div className="d-flex space-between-elements">
                <Spinner
                  animation="border"
                  variant="primary"
                  className="pr-2"
                ></Spinner>
                <h6 className="ml-3">Processing payment...</h6>
              </div>
            </Modal.Body>
          </Modal>

          {/* toast */}
          <ToastContainer position="top-end" className="p-3">
            <Toast
              onClose={() => setToast(false)}
              show={showToast}
              delay={3000}
              autohide
            >
              <Toast.Header
                onClick={() => () => setToast(false)}
                closeButton={false}
              >
                <strong className="me-auto text-warning">
                  Error Code {serverError?.code}
                </strong>
              </Toast.Header>
              <Toast.Body className="text-danger">
                {serverError?.message}
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </Container>
      )}
    </>
  )
}

// initial values
const initialValues = {
  payment: {
    mpesaPhoneNumber: "",
  },
}

// validation schema
const validationSchema = Yup.object({
  payment: Yup.object({
    mpesaPhoneNumber: Yup.string()
      .matches(/^[0-9]+$/, "invalid phone")
      .required("This field is required")
      .length(10, "invalid phone  format"),
  }),
})

export default Renew
