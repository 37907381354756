import React from 'react';
import { ErrorPage } from '../../components/Error';
const TooManyRequestsError = () => {
    const message = 'Too Many requests!!'
    const description = "Please try again after 1 minute. If this persist contact support."
    const code = 500
    return <ErrorPage message={message} errorCode={code} description={description} />
}

export default TooManyRequestsError;
