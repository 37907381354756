import React, { useEffect, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom";
import { BoxLoading } from 'react-loadingg';


import PlanCategory from '../../components/PlanCategory';

import Container from "react-bootstrap/Container"
import { AppContext } from "../../contexts/global.context";
import { addPackages } from "../../contexts/actions";
import { updateIsLoading } from "../../contexts/actions";
import { getPackages } from "../../apis";




function Home() {
  const navigate = useNavigate()
    const {company_id} = useParams();
    const {dispatch, plans, isLoading} = useContext(AppContext)

    useEffect(()=> {
      let isFetched = true
      const fetchData = async()=> {
        const plans = await getPackages(company_id);
        if(isFetched){
         dispatch(addPackages(plans))
         dispatch(updateIsLoading())
        }

      }
      fetchData()
      .catch(error=> {
        console.log(error)
        if(error.response.data) {
          navigate('/error/not/found') // we need to show contact here
        }
      })

      return ()=> isFetched=false
    }, [company_id])

  return (
    <Container>
       {isLoading ? <BoxLoading color="#0d6efd" />: <PlanCategory plans={plans || []} />}
    </Container>
  )
}

export default Home
