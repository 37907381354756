import React, { useState,useContext, useEffect } from "react"
import { Row, Col, Alert, Card, Button, Toast, ToastContainer } from "react-bootstrap"
import { useNavigate, useParams  } from "react-router-dom"
import { checkPayment } from "../../apis";
import { AppContext } from "../../contexts/global.context";
import { updateSupportDisplay } from "../../contexts/actions";

function ConfirmPaymentMpesa() {
    const navigate = useNavigate()
  const [paymentInfo, setPaymentInfo] = useState({});
  const [btnMessage, setBTNMessage] = useState('Please wait');
  const [btnStatus, setBtnStatus] = useState(true)
  const {company, dispatch} = useContext(AppContext);
  const [showToast, setToast] = useState(false);
  const [error, setError] = useState(null);
  const {ref_id} = useParams();

  useEffect(()=> {
    dispatch(updateSupportDisplay(true))
  }, [])

  useEffect(() => {
    let attempts = 0;
    const maxAttempts = 6;
    const interval = 1000; // 5 seconds

    const checkPaymentStatus = async () => {
      if (attempts < maxAttempts) {
        console.log(attempts, "is the interval")
        try {
          const paymentResponse = await checkPayment(ref_id);
          
          if (paymentResponse.status === 'completed' && paymentResponse.is_activated) {
            setBTNMessage('Payment Confirmed');
            navigate(`/login`, { replace: true });
            return; // Stop polling if payment is confirmed
          }else if (paymentResponse.status === 'failed') {
            navigate(`/plans/${company?.company_id}`)
          }
        } catch (error) {
          console.error('Error checking payment status:', error);
        }

        attempts++;
        if (attempts < maxAttempts) {
          const newInteval = interval * attempts
          setTimeout(checkPaymentStatus, newInteval);
        } else {
          setBTNMessage('Confirm Payment');
          setBtnStatus(false);
          setToast(true);
        }
      }
    };

    checkPaymentStatus();

    // Cleanup function to clear any remaining timeouts
    return () => clearTimeout(checkPaymentStatus);
  }, [ref_id]);


  const pendingAlert = (
    <Alert variant="warning">
      <h6>
        After Making the payment on MPESA, please click on the <b>Confirm Payment</b>{" "}
        button below to complete your payment
      </h6>
    </Alert>
  )

  const confirmedAlert = (
    <Alert variant="success">
      <h6>Your purchase was successfull</h6>
    </Alert>
  )

  const handleOnClick = async ()=> {
    try {
        setBTNMessage('Processing...');
        setBtnStatus(true);
        const paymentResponse = await checkPayment(ref_id);
        setPaymentInfo(paymentResponse)
        if (paymentResponse.status=== 'completed' && paymentResponse.is_activated) {
            setBTNMessage('Payment Confirmed');
            navigate(`/login`, {replace:true});
        }else if(paymentResponse.status === 'pending'){
          setBTNMessage('Please try again');
          setBtnStatus(false);
          setToast(true)
        } else {
          navigate(`/plans/${company?.company_id}`)
        }
        
    } catch (error) {
        // show toaster about the error
        setBTNMessage('Unxpected Error Happened');
        setBtnStatus(false);
        setError(error)
        setToast(true)
    }
  }

  const pendingBtn = (
    <Button disabled={btnStatus} onClick={handleOnClick} variant="primary" size="lg">
      {btnMessage}
    </Button>
  )

  const processingBtn = (
    <Button disabled={true} variant="secondary" size="lg">
      {btnMessage}
    </Button>
  )

  const {status} = paymentInfo

  const message = status=== 'completed' ? 'Payment Completed' : 'Payment in Progress'

  return (
    <>
    <Row className="align-col-center padding-for-bigger-screens">
      <Col md={4} xs={12}>
        <Card className="py-3">
          <Card.Body>
            <h1 className="text-center page-header my-4">{message}</h1>
            {status=== 'completed' ? confirmedAlert : pendingAlert}
            <div className="d-grid gap-2">
              {status=== 'completed' ? processingBtn : pendingBtn}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    {/* toast */}
    <ToastContainer position="top-end" className="p-3">
        <Toast onClose={() => setToast(false)} show={showToast} delay={3000} autohide >
          <Toast.Header onClick={()=> () => setToast(false)} closeButton= {false}>
            <strong  className="me-auto text-warning">Transaction is Pending!</strong>
          </Toast.Header >
          <Toast.Body className="text-success">{error?.response?.data?.message || "please wait"}</Toast.Body>
        </Toast>
        </ToastContainer>
    </>
  )
}

export default ConfirmPaymentMpesa
