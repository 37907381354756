import backgroundImage from './err4.jpg'

export const ErrorPage = ({message, errorCode, description}) => {
    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '93vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const overlayStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        position: 'absolute',
        top: '3.5rem',
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textAlign: 'center',
    }

    const defaultDescription = 'Please try again later. We shall fix this right away. If this persist, please reach out to the support'


    return (
        <div style={backgroundStyle}>
            <div style={overlayStyle}>
            <section class="tw-bg-blue-950 tw-mx-4 dark:tw-bg-gray-900">
                <div class="tw-py-8 tw-px-4 tw-mx-auto tw-max-w-screen-xl lg:tw-py-16 lg:tw-px-6">
                    <div class="tw-mx-auto tw-max-w-screen-sm tw-text-center">
                        <h1 class="tw-mb-4 tw-text-7xl tw-tracking-tight tw-font-extrabold lg:tw-text-9xl tw-text-primary-600 dark:tw-text-primary-500">{errorCode || 500}</h1>
                        <p class="tw-mb-4 tw-text-3xl tw-tracking-tight tw-font-bold tw-text-gray-900 md:tw-text-4xl dark:tw-text-white">{message}</p>
                        <p class="tw-mb-4 tw-text-lg tw-font-light tw-text-gray-500 dark:tw-text-gray-400">{description || defaultDescription}</p>
                    </div>   
                </div>
            </section>
            </div>
        </div>
    )
}