import React, { useEffect, useState, useContext } from "react"
import { Routes, Route, useNavigate } from "react-router-dom"
import IndexPage from "./default"
import Login from "./components/Login"
import NavBar from "./components/NavBar"
import "./index.css"
import Home from "./pages/Home"
import Renew from "./components/Renew"
import ConfirmPaymentMpesa from "./components/ConfirmPaymentMpesa"
import Support from "./components/Support/index"
import { getIspInfo } from "./apis"
import { AppContext } from "./contexts/global.context"
import { setCompanyInfo } from "./contexts/actions"
import IspSuspendedError from './pages/IspSuspendedError'
import ValidationError from "./pages/ValidationError"
import IspMissingError from "./pages/IspMissingError"
import HotspotError from "./pages/HotspotError"
import RouterMissingError from "./pages/RouterMissingError"
import DefaultError from "./pages/DefaultError"
import UnauthorizedError from "./pages/UnauthorizedError"
import PrivateRoutes from "./components/PrivateRoutes"
import TooManyRequestsError from "./pages/TooManyRequestsError"

function App() {
  const { dispatch, company } = useContext(AppContext)
  const [isAuth, setAuth] = useState(true)



  useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await getIspInfo()
          dispatch(setCompanyInfo(data))
          setAuth(true)
        } catch (error) {
          setAuth(false)
        }
      }
      fetchData()
  }, [])
  // we need to load data here
  return (
    <div className="App">
      <NavBar />
      <Routes>
        {/* Non Auth Routes */}
        <Route path="/" element={<IndexPage />} />
        <Route path="/error/ips/suspended" element={<IspSuspendedError />} />
        <Route path="/error/router/misconfigured" element={<ValidationError />} />
        <Route path="/error/isp/not-configured" element={<IspMissingError />} />
        <Route path="error/router/not-set" element={<RouterMissingError />} />
        <Route path="error/hotspot/misconfigured" element={<HotspotError />} />
        {/* auth routes */}
        <Route element={<PrivateRoutes isAuthenticated={isAuth} />}>
          <Route path="/plans/:company_id" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/renew/:plan_id" element={<Renew />} />
          <Route path="/check/payment/:ref_id" element={<ConfirmPaymentMpesa />} />
        </Route>
        <Route path="error/unauthorized" element={<UnauthorizedError />} />
        <Route path="error/too-many-requests" element={<TooManyRequestsError />} />
        <Route path="*" element={<DefaultError />} />
      </Routes>
      <Support company={company} />
    </div>
  )
}

export default App
