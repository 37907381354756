import {
  ADD_PACKAGES,
  UPDATE_LOADING_STATUS,
  SELECT_PACKAGE,
  CHANGE_FORM_FIELD,
  SET_CURRENT_URL,
  SET_COMPANY_INFO,
  UPDATE_SUPPORT_DISPLAY
} from "./actionTypes"

export const intialState = {
  currentUrl: "https://diralink.com",
  plans: [],
  postentialUser: {},
  isLoading: true,
  selectedPlan: {},
  create: {},
  edit: {},
  company: {},
  showSupportButton: true,
}

function appReducer(state = intialState, action) {
  switch (action.type) {
    case ADD_PACKAGES: {
      return {
        ...state,
        plans: action.payload,
      }
    }
    case UPDATE_LOADING_STATUS: {
      return {
        ...state,
        isLoading: action.status, //! change this
      }
    }
    case SELECT_PACKAGE: {
      return {
        ...state,
        selectedPlan: action.payload,
      }
    }
    case CHANGE_FORM_FIELD: {
      const { name, value } = action.payload
      const newInfo = state.create
      return {
        ...state,
        create: { ...newInfo, [name]: value },
      }
    }
    case SET_CURRENT_URL: {
      const { url } = action.payload
      return {
        ...state,
        currentUrl: url,
      }
    }
    case SET_COMPANY_INFO: {
      const data = action.payload
      return {
        ...state,
        company: data,
      }
    }
    case UPDATE_SUPPORT_DISPLAY: {
        const data = action.payload
        return {
          ...state,
          showSupportButton: data,
        }
      }
    default: {
      return state
    }
  }
}

export default appReducer
