import React from 'react';
import { ErrorPage } from '../../components/Error';

const RouterMissingError = () => {
    const message = 'Router is misconfigured.'
    const code = 404
    return <ErrorPage message={message} errorCode={code} />
}

export default RouterMissingError;
